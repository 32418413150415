<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="info">
                <div class="list">
                    <div class="acinfo">{{ $t('Company.information') }}</div>
                    <div class="one"><span>*</span>{{ $t('corporate.name') }}</div>
                    <div class="account"><input type="text" v-model="keywords" @focus="qcac()"></div>
                    <div class="three"><span>*</span>{{ $t('Industry.type') }}</div>
                   <div>
                      <a-select default-value="" style="width: 600px;margin-top:20px;height:40px" @change="handleChange">
                        <a-select-option value="">
                          {{ $t('select.industry') }}
                        </a-select-option>
                        <a-select-option :value="item.categoryId" v-for="(item,index) in cateList" :key="index">
                          {{item.categoryName}}
                        </a-select-option>
                      </a-select>
                    </div>
                    <button class="submit" @click="regsub()">{{ $t('register') }}</button>
                    <div class="acone" v-show="subtag">{{ $t('fill.correctly') }}</div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   name: "Register",
   components: {
    Footer,
    Header
  },
   data () {
    return {
        keywords: '',
        userId: this.$route.params.userId,
        show: true,
        count: '获取验证码',
        timer: null,
        tag: false,
        pwd: '',
        pwdtag: false,
        rwd: '',
        rwdtag: false,
        code: '',
        subtag: false,
        cateList: [],
        categoryIds: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    //select选择
    handleChange(value) {
      this.categoryIds[0] = value;
      console.log(this.categoryIds);
    },
     //去除提示信息
    qcac() {
        this.subtag = false
    },
    // 获取行业分类
     getList () {
       this.$common.fetchList('/exhibition/category/lists?parentId=0', {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.cateList = result
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
    //注册公司信息
    regsub() {
        if (this.keywords == '' || this.categoryIds == '') {
            this.subtag = true
            return 
        }
        const params = {
            userId: this.userId,
            companyName: this.keywords,
            categoryIds: this.categoryIds
        }
        this.$common.handlePost('/user/company/add', params).then(data => {
        if (data.code === 200) {
            this.$layer.alert(data.msg);
            this.$router.push({name: 'Home'})
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/register2.less';
</style>